import { Club } from "../../types/club/Club";
import { TrainingGroup } from "../../types/training-group/TrainingGroup";
import { SportAssociation } from "./SportAssociation";

export type SingleClubResponse = {
    club?: Club,
    trainingGroups: TrainingGroup[]
}

export const fetchClub = async (backendUrl: string, id: string): Promise<SingleClubResponse> => {
    const response = await fetch(backendUrl + "/club/" + id, {
        headers: {
            "content-type": "application/json",
            "Sport-Association": SportAssociation.LSB
        },
    });
    const data = await response.json();
    return data;
}